import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'
import { Controller } from 'react-scrollmagic-r18'
import NoticeModal from '@/features/common/components/NoticeModal'
import { useActiveTime } from '@/features/common/hooks/useActiveTime'
import { HomePanel } from '@/features/home/components'

const EventModal = dynamic(() => import('@/features/common/components/EventModal'), { ssr: false })
const Notice = dynamic(() => import('@/features/common/components/Notice0719'), { ssr: false })

function Home() {
  const bannerPeriods: [string, string][] = [['2024-10-01 00:00:00', '2024-10-31 23:59:59']]
  const noticePeriods: [string, string][] = [['2024-07-19 00:00:00', '2024-07-26 23:59:59']]
  const [isOpenedBannerModal, setIsOpenedBannerModal] = useState(false)
  const [isOpenedNoticeModal, setIsOpenedNoticeModal] = useState(false)
  const [isBannerActiveTime] = useActiveTime({ periods: bannerPeriods, isOnce: false })
  const [isNoticeActiveTime] = useActiveTime({ periods: noticePeriods, isOnce: false })

  const handleClose = () => {
    setIsOpenedNoticeModal(false)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsOpenedBannerModal(true)
      setIsOpenedNoticeModal(true)
    }
  }, [])

  return (
    <Controller>
      <HomePanel />
      {isOpenedBannerModal && isBannerActiveTime && <EventModal />}
      {isOpenedNoticeModal && isNoticeActiveTime && (
        <NoticeModal isOpened={isOpenedNoticeModal} handleClose={handleClose}>
          <Notice />
        </NoticeModal>
      )}
    </Controller>
  )
}

export default Home
